export const AUTHORIZATION_KEY = 'web2chat_token';

export const PLANS = [
  {
    name: 'Basic',
    price: 19,
    botCount: 1,
    pageCount: 25,
    plan_id: '51693',
  },
  {
    name: 'Standard',
    price: 49,
    botCount: 2,
    pageCount: 49,
    plan_id: '51694',
  },
  {
    isRecommend: true,
    name: 'Premium',
    price: 99,
    botCount: 5,
    pageCount: 500,
    plan_id: '51695',
  },
  {
    name: 'Enterprise',
    price: 499,
    botCount: 50,
    pageCount: 5000,
    plan_id: '51693',
  },
];
