import Styled from 'styled-components';
import { Alert } from 'antd';

const GlobalUtilityStyle = Styled.div`
  .ant-card-body-p-25{
    .ant-card-body {
      padding: 25px!important;
    }
  }
  .ant-card-body-px-0{
    .ant-card-body {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }

  .ant-card-body-py-25{
    .ant-card-body {
      padding-top: 25px!important;
      padding-bottom: 25px!important;
    }
  }

  .ant-card-body-p-0{
    .ant-card-body {
      padding: 0!important;
    }
  }

  .ant-card-body-pt-0{
    .ant-card-body {
      padding-top: 0!important;
    }
  }

  .ant-card-body-pb-0{
    .ant-card-body {
      padding-bottom: 0!important;
    }
  }

  .ant-card-head-px-25{
    .ant-card-head {
      padding-left: 25px!important;
      padding-right: 25px!important;
    }
  }
  .ant-card-head-mb-0{
    .ant-card-head {
      margin-bottom: 0 !important;
    }
  }

  .ant-card-body-center{
    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }
  .ant-card-body-align-center{
    .ant-card-body {
      text-align: center;
    }
  }

  .bg-regularBG-header-bg{
    .ant-card-head{
      background-color: ${({ theme }) => theme[theme.mainContent]['main-background-light']};
    }
  }

  .ant-card-title-bb-default{··
    .ant-card-head{
      border-bottom: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    }
  }

  .ant-card-head-b-none{
    .ant-card-head {
      border:none !important;
    }
  }

  .ant-card-head-title-base{
    .ant-card-head {
      .ant-card-head-title {
        padding: 18px 0;
        font-size: 18px;
        font-weight: 600;
        color: ${({ theme }) => theme[theme.mainContent]['text-dark']};
        @media only screen and (max-width: 575px){
          padding-bottom: 5px;
        }
      }
    }
  }
  .ant-card-title-mb-22 {
    .ant-card-body {
      h1 {
        margin-bottom: 22px;
      }
    }
  }
  
  .ant-card-extra{
    .ant-dropdown-trigger{
      >svg,>img{
        color:${({ theme }) => theme[theme.mainContent]['gray-light-text']};
        width: 24px;
        height: 24px;
      }
    }
  }

  .ant-dropdown{
    a i, a svg, a img {
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
    }
    a {
        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
    }

      background-color:${({ theme }) => theme[theme.mainContent]['white-background']};
      border: 0;
      border-radius: 5px;
      box-shadow: 0 5px 20px #9299b820;
      padding:4px 0;
      >a{
          display:flex;
          align-items:center;
          font-weight:400;
          font-size:14px;
          padding:6px 12px;
          color:${({ theme }) => theme[theme.mainContent]['gray-text']};
          transition:0.3s;
          svg{
              color:${({ theme }) => theme[theme.mainContent]['gray-light-text']};
              width: 14px;
              height: 14px;
              margin-right: 8px;
              transition:0.3s;
          }
          &:hover{
              background: rgba(${({ theme }) => theme[theme.mainContent]['primary-color-rgb']},.10);
              color:${({ theme }) => theme[theme.mainContent].primary};
              svg{
                  color:currentColor;
              }
          }
      }
  }

  .table-responsive {
    .ant-table-tbody > tr > td {
      &.ant-table-column-sort {
        background: transparent;
      }
    }
    .ant-table-thead > tr > th {
      background-color: ${({ theme }) => theme[theme.mainContent]['light-background']};
      border-color: ${({ theme }) => theme[theme.mainContent]['light-border']};
      background:transparent;
    }
    .ant-table-tbody > tr:hover > td {
      background-color: transparent !important;
    }
  }

  .table-bg-transparent {
    .ant-table-wrapper {
      .ant-table {
        background: transparent;
      }
    }
  }

  .table-pl-0{
    tbody{
      tr{
        td{
          &:first-child{
            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0 !important;
          }
        }
      }
    }
  }

  .table-pr-0{
    tbody{
      tr{
        td{
          &:last-child{
            padding-right: 0 !important;
          }
        }
      }
    }
  }

  .table-pt-15{
    tbody{
      tr{
        &:first-child{
          td{
            padding-top: 15px !important;
          }
        }
      }
    }
  }

  .table-head-none {
    table {
      thead {
        display: none;
      }
    }
  }

  .table-head-rounded {
    table {
      thead {
        > tr {
          &:first-child {
            th {
              &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
              &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
      }
    }
  }

  .table-th-border-none {
    thead {
      tr {
        th {
          border: none;
        }
      }
    }
  }

  .table-th-shape-none {
    thead {
      th {
        &:before {
          display: none;
        }
      }
    }
  }

  .table-th-text-light {
    thead {
      th {
        color: ${({ theme }) => theme['light-color']};
      }
    }
  }

  .table-last-th-text-right {
    thead {
      th {
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .table-tr-px-30 {
    table {
      tr {
        th,
        td {
          &:first-child {
            padding-left: 30px !important;
          }
          &:last-child {
            padding-right: 30px !important;
          }
        }
      }
    }
  }
  .table-border-b-none{
    tbody{
      tr{
        &:last-child{
          td{
            border-bottom: none;
          }
        }
      }
    }
  }

  .table-td-border-none {
    tbody {
      tr {
        td {
          border: none;
        }
      }
    }
  }

  .table-td-text-body dark:text-white60 {
    tbody {
      tr {
        td {
          color: ${({ theme }) => theme['gray-color']};
        }
      }
    }
  }

  .table-last-td-text-right {
    tbody {
      tr {
        td {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .table-selection-col-pl-25 {
    table {
      tr {
        .ant-table-selection-column {
          padding-left: 25px;
        }
      }
    }
  }

  .table-tr-selected-background-transparent {
    tbody {
      tr.ant-table-row-selected {
        td {
          background: transparent;
        }
      }
    }
  }

  .table-tr-hover-shadow {
    tbody {
      tr {
        &:hover {
          box-shadow: 0px 10px 15px rgba(116,116,116,0.08);
        }
      }
    }
  }

  .svg-w-h-10{
    svg{
      width: 10px;
      height: 10px;
    }
  }

  .svg-w-full{
    svg{
      width: 100%;
      height: 100%;
    }
  }

  .inside-flex-align-center{
    >div{
      display:flex;
      align-items:center;
    }
  }

  .ant-btn-loading-icon{
    .ant-btn-loading-icon{
      display:flex;
      align-items:center;
    }
  }

  .anticon svg {
    color: ${({ theme }) => theme[theme.mainContent]['white-color']};
  }

  .ant-progress-status-success {
    .anticon svg {
      color: ${({ theme }) => theme['success-color']};
    }
  }

  .progress-text-body dark:text-white60 {
    .ant-progress-text {
      color: ${({ theme }) => theme['gray-color']};
    }
  }

  .custom-radio-group {
    .ant-radio-checked {
      .ant-radio-inner {
        border-width: 4px;
        &:after {
          background: ${({ theme }) => theme['white-color']};
        }
      }
    }
  }

  .form-label-w-full {
    .ant-form-item-row {
      flex-direction: column;
    }
  }

  .form-label-text-left {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .form-label-text-dark {
    .ant-form-item-label label {
      color: ${({ theme }) => theme['dark-color']};
    }
  }

  .form-label-text-semibold {
    .ant-form-item-label label {
      font-weight: 600;
    }
  }

  .checkbox-label-w-full {
    label {
      margin-right: 0;
      span {
        &:not(:first-child){
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
        }
      }
    }
  }

  .custom-filter-rating {
    .ant-checkbox-wrapper {
      align-items: center;
      .ant-rate {
        margin-right: 10px;
        li {
          margin-right: 2px;
          svg {
            color: ${({ theme }) => theme['warning-color']};
          }
        }
      }
    }
  }

`;

const AlertWrap = Styled(Alert)`
  border-radius: ${({ shape }) => (!shape ? '4px' : '40px')} !important;
  border-width: 0 !important;
  margin: 0!important;
  padding: 16.5px 20px!important;
  .ant-alert-message{
    line-height: 1.8;
    margin-bottom: 0 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  &.ant-alert-with-description{
    .ant-alert-content{
      line-height: 1;
    }
    .ant-alert-message{
      margin-bottom: 10px !important;
      line-height: 1;
    }
    .ant-alert-description{
      font-size: 15px;
      line-height: 1.35;
    }
  }
  &.ant-alert-closable {
    .ant-alert-message{
      display: block;
    }
  }
  .ant-alert-message, 
  .ant-alert-description {
    color: ${({ type, theme }) => theme[`${type}-color`]};
  }
  &.ant-alert-with-description{
    .ant-alert-message{
      color: ${({ type, theme }) => theme[`${type}-color`]};
    }
  }
  .ant-alert-close-text{
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
  }

  ${({ showIcon, theme }) =>
    showIcon &&
    `padding: 16px 20px 20px!important;
    alert-empty-message{
      padding: 11px 40px 11px!important;
    }
    .ant-alert-icon{
      top: 20px !important;
      ${theme.rtl ? 'right' : 'left'}: 15px !important;
    }
    .ant-alert-message{
      margin-top: -2px;
    }
    i.ant-alert-icon {
      color: ${({ type }) => theme[`${type}-color`]} !important;
      background: #ffffff80 !important;
      height: 100%;
      width: 50px;
      position: absolute;
      top: 0;
      ${theme.rtl ? 'right' : 'left'}: 0;
    }`}


  .ant-alert-close-icon {
    top: 12px !important;
    ${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 20px !important;
    svg,
    span,
    img,
    i{
      width: 8px;
      height: 8px;
    }
  }
`;

const OverviewCard = Styled.div`
    background: ${({ theme }) => theme[theme.mainContent]['white-background']};
    border-radius: 10px;
    padding: 25px 25px 20px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    margin-bottom: 30px;
    ${({ theme }) => (theme.topMenu ? 'min-height: 595px' : 'min-height: auto')};
    @media only screen and (max-width: 991px){
        min-height: auto;
    }
    &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 215px;
        background:linear-gradient(45deg, ${({ theme }) => theme['secondary-color']}, ${({ theme }) =>
  theme['warning-color']});
  ${({ theme }) => (theme.rtl ? 'right' : 'left')}:0;
        top: 0;
        z-index:-1;
    }
    .overview-box{
        .ant-card-body{
            padding: 22px 25px 14px !important;
        }
        .ant-progress{
            margin-bottom: 15px;
        }
        .ant-progress-bg{
            height: 6px !important;
        }
        .overview-box-single{
            h1{
                margin-bottom: 0;
            }
            p{
                color: ${({ theme }) => theme['light-color']};
            }
        }
        .growth-downward,
        .growth-upward{
            span{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 6px;
            }
        }
        .overview-box-percentage{
            font-weight: 500;
        }
    }
    .ant-card{
        box-shadow: 0 10px 30px rgba(146,153,184,0.15);
        .growth-upward{
            color: ${({ theme }) => theme['success-color']};
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            span{
                color: ${({ theme }) => theme['light-gray-color']};
                font-weight: 400;
                font-size: 13px;
            }
        }
        .growth-downward{
            color: ${({ theme }) => theme['danger-color']};
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            span{
                color: ${({ theme }) => theme['light-gray-color']};
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
    .overview-head{
        margin-bottom: 70px;
        h1{
            font-size: 16px;
            font-weight: 500;
            color: #fff;
        }
        .ant-btn-default{
            font-size: 12px;
            background: rgba(255,255,255,0.1);
            padding: 0px 11px;
            border: 0 none;
            color: #fff;
            svg,
            img,
            i{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 8px;
            }
        }
    }
`;

export { GlobalUtilityStyle, AlertWrap, OverviewCard };
