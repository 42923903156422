import * as endpoints from './endpoints';
import instance from '../axiosClient';

import { AUTHORIZATION_KEY } from '../global_constants';

export const getBotLogoUploadPath = (botId) => {
  return `${instance.defaults.baseURL}${endpoints.BOT_UPLOAD_LOGO_PATH.replace('{}', botId)}`;
};

export const getUserPicUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.USER_API_PATH}`;
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common.Authorization;
  return instance.post(endpoints.LOGIN_API_PATH, payload);
};

export const verifyLogin = (token) => {
  delete instance.defaults.headers.common.Authorization;
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_TOKEN_VERIFY_API_PATH, { token })
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common.Authorization = `Bearer ${data.access}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      instance
        .get(endpoints.USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const updateUser = (userId, data) => {
  return instance.patch(endpoints.USER_DETAIL_API_PATH.replace('{}', userId), data);
};

export const updateUserPic = (userId, formData) => {
  return instance.patch(endpoints.USER_DETAIL_API_PATH.replace('{}', userId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUserUsage = () => {
  return instance.get(endpoints.USER_USAGE_API_PATH);
};

export const listPlan = () => {
  return instance.get(endpoints.PLAN_LIST_API_PATH);
};

export const updateSubscription = (planId) => {
  return instance.patch(endpoints.USER_SUBSCRIBE_UPDATE_API_PATH.replace('{plan_id}', planId));
};

export const listBot = () => {
  return instance.get(endpoints.BOT_API_PATH);
};

export const getBotDetail = (botId) => {
  return instance.get(endpoints.BOT_DETAIL_PATH.replace('{}', botId));
};

export const createBot = (data) => {
  return instance.post(endpoints.BOT_API_PATH, data);
};

export const updateBot = (data, botId) => {
  return instance.patch(endpoints.BOT_DETAIL_PATH.replace('{}', botId), data);
};

export const deleteBot = (botId) => {
  return instance.delete(endpoints.BOT_DETAIL_PATH.replace('{}', botId));
};

export const saveBotAppearance = (botId, data) => {
  return instance.post(endpoints.BOT_APPEARANCE_PATH.replace('{}', botId), data);
};

export const trainBot = (botId) => {
  return instance.post(endpoints.BOT_TRAIN_PATH.replace('{}', botId));
};
export const getTrainPages = (botId, filters) => {
  let path = endpoints.BOT_PAGE_SOURCE_PATH.replace('{}', botId);
  const params = new URLSearchParams(filters).toString();
  path = path.concat('?', params);
  return instance.get(path);
};
export const createTrainPage = (botId, data) => {
  return instance.post(endpoints.BOT_PAGE_SOURCE_PATH.replace('{}', botId), data);
};
export const deleteTrainPage = (botId, pageId) => {
  return instance.delete(endpoints.BOT_PAGE_SOURCE_DETAIL_PATH.replace('{}', botId).replace('{page_id}', pageId));
};
export const refreshTrainPage = (botId, pageId) => {
  return instance.post(endpoints.BOT_PAGE_SOURCE_REFRESH_PATH.replace('{}', botId).replace('{page_id}', pageId));
};

export const listTrainFiles = (botId) => {
  return instance.get(endpoints.BOT_FILE_SOURCE_PATH.replace('{}', botId));
};
export const createTrainFile = (botId, formData) => {
  return instance.post(endpoints.BOT_FILE_SOURCE_PATH.replace('{}', botId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteTrainFile = (botId, fileId) => {
  return instance.delete(endpoints.BOT_FILE_SOURCE_DETAIL_PATH.replace('{}', botId).replace('{file_id}', fileId));
};

export const updateTrainFreeText = (botId, data) => {
  return instance.post(endpoints.BOT_FREE_TEXT_SOURCE_SAVE_PATH.replace('{}', botId), data);
};
export const deleteTrainFreeText = (botId) => {
  return instance.delete(endpoints.BOT_FREE_TEXT_SOURCE_DELETE_PATH.replace('{}', botId));
};

export const listTrainDomains = (botId) => {
  return instance.get(endpoints.BOT_DOMAIN_SOURCE_PATH.replace('{}', botId));
};
export const deleteTrainDomainDetail = (botId, domainId) => {
  return instance.delete(endpoints.BOT_DOMAIN_SOURCE_DETAIL_PATH.replace('{}', botId).replace('{source_id}', domainId));
};
export const createTrainDomain = (botId, data) => {
  return instance.post(endpoints.BOT_DOMAIN_SOURCE_PATH.replace('{}', botId), data);
};

export const listTrainZendesks = (botId) => {
  return instance.get(endpoints.BOT_SOURCE_ZENDESK_PATH.replace('{}', botId));
};
export const deleteTrainZendesk = (botId, zendeskUUid) => {
  return instance.delete(
    endpoints.BOT_SOURCE_ZENDESK_DETAIL_PATH.replace('{}', botId).replace('{source_id}', zendeskUUid),
  );
};
export const createTrainZendesk = (botId, data) => {
  return instance.post(endpoints.BOT_SOURCE_ZENDESK_PATH.replace('{}', botId), data);
};
export const getTrainZendeskArticles = (botId, zendeskUUid, filters = {}) => {
  let path = endpoints.BOT_SOURCE_ZENDESK_ARTICLE_PATH.replace('{}', botId).replace('{source_id}', zendeskUUid);
  const params = new URLSearchParams(filters).toString();
  path = path.concat('?', params);
  return instance.get(path);
};
export const disableTrainZendeskArticle = (botId, zendeskUUid, articleUUid) => {
  let path = endpoints.BOT_SOURCE_ZENDESK_ARTICLE_DETAIL_PATH.replace('{}', botId).replace('{source_id}', zendeskUUid);
  path = path.replace('{article_id}', articleUUid);
  return instance.delete(path);
};
export const enableTrainZendeskArticle = (botId, zendeskUUid, articleUUid) => {
  let path = endpoints.BOT_SOURCE_ZENDESK_ARTICLE_ENABLE_PATH.replace('{}', botId).replace('{source_id}', zendeskUUid);
  path = path.replace('{article_id}', articleUUid);
  return instance.post(path);
};
export const refreshTrainZendeskArticle = (botId, zendeskUUid, articleUUid) => {
  let path = endpoints.BOT_SOURCE_ZENDESK_ARTICLE_REFRESH_PATH.replace('{}', botId).replace('{source_id}', zendeskUUid);
  path = path.replace('{article_id}', articleUUid);
  return instance.post(path);
};

export const getTrainDomainPages = (botId, domainId, filters = {}) => {
  let path = endpoints.BOT_DOMAIN_SOURCE_PAGE_PATH.replace('{}', botId).replace('{source_id}', domainId);
  const params = new URLSearchParams(filters).toString();
  path = path.concat('?', params);
  return instance.get(path);
};
export const disableTrainDomainPage = (botId, domainId, pageId) => {
  let path = endpoints.BOT_DOMAIN_SOURCE_PAGE_DETAIL_PATH.replace('{}', botId).replace('{source_id}', domainId);
  path = path.replace('{page_id}', pageId);
  return instance.delete(path);
};
export const enableTrainDomainPage = (botId, domainId, pageId) => {
  let path = endpoints.BOT_DOMAIN_SOURCE_PAGE_ENABLE_PATH.replace('{}', botId).replace('{source_id}', domainId);
  path = path.replace('{page_id}', pageId);
  return instance.post(path);
};
export const refreshTrainDomainPage = (botId, domainId, pageId) => {
  let path = endpoints.BOT_DOMAIN_SOURCE_PAGE_REFRESH_PATH.replace('{}', botId).replace('{source_id}', domainId);
  path = path.replace('{page_id}', pageId);
  return instance.post(path);
};

export const createTrainQnASource = (botId, data) => {
  return instance.post(endpoints.BOT_QNA_SOURCE_PATH.replace('{}', botId), data);
};

export const getBotStatistics = (botId) => {
  return instance.get(endpoints.BOT_STATISTICS_PATH.replace('{}', botId));
};

export const listBotConversationSessions = (botId, filters = {}) => {
  let path = endpoints.BOT_SESSIONS_PATH.replace('{}', botId);
  const params = new URLSearchParams(filters).toString();
  path = path.concat('?', params);
  return instance.get(path);
};
export const getBotSessionDetail = (botId, sessionId) => {
  return instance.get(endpoints.BOT_SESSION_DETAIL_PATH.replace('{}', botId).replace('{session_uuid}', sessionId));
};
export const blockUserSession = (botId, sessionId) => {
  return instance.post(endpoints.BOT_SESSION_BLOCK_PATH.replace('{}', botId).replace('{session_uuid}', sessionId));
};
export const markReadAllSession = (botId, sessionId) => {
  return instance.post(
    endpoints.BOT_SESSION_MARK_READ_ALL_PATH.replace('{}', botId).replace('{session_uuid}', sessionId),
  );
};

export const listBotSessionConversations = (botId, sessionId, filters = {}) => {
  let path = endpoints.BOT_SESSION_CONVERSATION_PATH.replace('{}', botId).replace('{session_uuid}', sessionId);
  const params = new URLSearchParams(filters).toString();
  path = path.concat('?', params);
  return instance.get(path);
};
export const readBotSessionConversations = (botId, sessionId, data) => {
  const path = endpoints.BOT_SESSION_CONVERSATION_READ_PATH.replace('{}', botId).replace('{session_uuid}', sessionId);
  return instance.post(path, data);
};

export const listBotPrompts = (botId) => {
  return instance.get(endpoints.BOT_PROMPT_PATH.replace('{}', botId));
};
export const createBotPrompt = (botId, data) => {
  return instance.post(endpoints.BOT_PROMPT_PATH.replace('{}', botId), data);
};
export const updateBotPrompt = (botId, data, promptId) => {
  const path = endpoints.BOT_PROMPT_DETAIL_PATH.replace('{}', botId).replace('{prompt_uuid}', promptId);
  return instance.patch(path, data);
};
export const deleteBotPrompt = (botId, promptId) => {
  const path = endpoints.BOT_PROMPT_DETAIL_PATH.replace('{}', botId).replace('{prompt_uuid}', promptId);
  return instance.delete(path);
};

export const getBotAiConversations = (botId, filters = {}) => {
  let path = endpoints.BOT_AI_CONVERSATION_PATH.replace('{}', botId);
  const params = new URLSearchParams(filters).toString();
  path = path.concat('?', params);
  return instance.get(path);
};
export const invalidateAllAiConversation = (botId) => {
  return instance.delete(endpoints.BOT_AI_CONVERSATION_INVALIDATE_ALL_PATH.replace('{}', botId));
};
export const invalidateAiConversation = (botId, conversationId) => {
  const path = endpoints.BOT_AI_CONVERSATION_INVALIDATE_PATH.replace('{}', botId).replace(
    '{conversation_id}',
    conversationId,
  );
  return instance.delete(path);
};
