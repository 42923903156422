import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import AccountHook from '../../hooks/account';
import withAdminLayout from '../../layout/withAdminLayout';

const CreateBot = lazy(() => import('../../container/bot'));

const Dashboard = lazy(() => import('../../container/dashboard'));
const SourcesPage = lazy(() => import('../../container/sources'));
const AiRepliesPage = lazy(() => import('../../container/aiReplies'));
const SettingsPage = lazy(() => import('../../container/settings'));
const ConversationPage = lazy(() => import('../../container/conversation'));
const PromptPage = lazy(() => import('../../container/customization/prompts'));
const PricingPlans = lazy(() => import('../../container/pricingPlans'));
const AppearancePage = lazy(() => import('../../container/customization/appearance'));

const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(({ accountData }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      {accountData.has_bots && accountData.subscription ? (
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="sources" element={<SourcesPage />} />
          {/* <Route path="pages" element={<TranPage />} /> */}
          <Route path="create-bot" element={<CreateBot />} />
          <Route path="appearance" element={<AppearancePage />} />
          <Route path="prompts" element={<PromptPage />} />
          <Route path="inbox" element={<ConversationPage />} />
          <Route path="ai-replies" element={<AiRepliesPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="billing" element={<PricingPlans />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          {accountData.subscription ? (
            <>
              <Route index element={<CreateBot />} />
              <Route path="billing" element={<PricingPlans />} />
            </>
          ) : (
            <>
              <Route index element={<PricingPlans />} />
              <Route path="billing" element={<PricingPlans />} />
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Suspense>
  );
});

Admin.propTypes = {
  accountData: PropTypes.object,
};

export default AccountHook(withAdminLayout(Admin));
