export const USER_API_PATH = 'user/';
export const LOGIN_API_PATH = '/user/login/';
export const USER_USAGE_API_PATH = 'user/usage';
export const USER_DETAIL_API_PATH = `${USER_API_PATH}{}/`;
export const LOGIN_TOKEN_VERIFY_API_PATH = '/user/login/verify/';

export const PLAN_LIST_API_PATH = 'plans/';

export const USER_SUBSCRIBE_API_PATH = 'subscribe/';
export const USER_SUBSCRIBE_UPDATE_API_PATH = `${USER_SUBSCRIBE_API_PATH}{plan_id}`;

export const BOT_API_PATH = 'bots/';
export const BOT_DETAIL_PATH = 'bots/{}/';
export const BOT_TRAIN_PATH = `${BOT_DETAIL_PATH}train/`;
export const BOT_APPEARANCE_PATH = `${BOT_DETAIL_PATH}appearance/`;

export const BOT_FREE_TEXT_SOURCE_SAVE_PATH = `${BOT_DETAIL_PATH}save-free-text/`;
export const BOT_FREE_TEXT_SOURCE_DELETE_PATH = `${BOT_DETAIL_PATH}delete-free-text/`;

export const BOT_FILE_SOURCE_PATH = `${BOT_DETAIL_PATH}source/files/`;
export const BOT_FILE_SOURCE_DETAIL_PATH = `${BOT_FILE_SOURCE_PATH}{file_id}/`;

export const BOT_PAGE_SOURCE_PATH = `${BOT_DETAIL_PATH}source/pages/`;
export const BOT_PAGE_SOURCE_DETAIL_PATH = `${BOT_PAGE_SOURCE_PATH}{page_id}/`;
export const BOT_PAGE_SOURCE_REFRESH_PATH = `${BOT_PAGE_SOURCE_DETAIL_PATH}refresh/`;

export const BOT_DOMAIN_SOURCE_PATH = `${BOT_DETAIL_PATH}source/domains/`;
export const BOT_DOMAIN_SOURCE_DETAIL_PATH = `${BOT_DOMAIN_SOURCE_PATH}{source_id}/`;
export const BOT_DOMAIN_SOURCE_PAGE_PATH = `${BOT_DOMAIN_SOURCE_DETAIL_PATH}pages/`;
export const BOT_DOMAIN_SOURCE_PAGE_DETAIL_PATH = `${BOT_DOMAIN_SOURCE_PAGE_PATH}{page_id}/`;
export const BOT_DOMAIN_SOURCE_PAGE_ENABLE_PATH = `${BOT_DOMAIN_SOURCE_PAGE_DETAIL_PATH}enable/`;
export const BOT_DOMAIN_SOURCE_PAGE_REFRESH_PATH = `${BOT_DOMAIN_SOURCE_PAGE_DETAIL_PATH}refresh/`;

export const BOT_SOURCE_ZENDESK_PATH = `${BOT_DETAIL_PATH}source/zendesks/`;
export const BOT_SOURCE_ZENDESK_DETAIL_PATH = `${BOT_SOURCE_ZENDESK_PATH}{source_id}/`;
export const BOT_SOURCE_ZENDESK_ARTICLE_PATH = `${BOT_SOURCE_ZENDESK_DETAIL_PATH}articles/`;
export const BOT_SOURCE_ZENDESK_ARTICLE_DETAIL_PATH = `${BOT_SOURCE_ZENDESK_ARTICLE_PATH}{article_id}/`;
export const BOT_SOURCE_ZENDESK_ARTICLE_ENABLE_PATH = `${BOT_SOURCE_ZENDESK_ARTICLE_DETAIL_PATH}enable/`;
export const BOT_SOURCE_ZENDESK_ARTICLE_REFRESH_PATH = `${BOT_SOURCE_ZENDESK_ARTICLE_DETAIL_PATH}refresh/`;

export const BOT_QNA_SOURCE_PATH = `${BOT_DETAIL_PATH}source/qnas/`;

export const BOT_STATISTICS_PATH = `${BOT_DETAIL_PATH}statistics/`;
export const BOT_UPLOAD_LOGO_PATH = `${BOT_DETAIL_PATH}/upload_logo`;

export const BOT_PROMPT_PATH = `${BOT_DETAIL_PATH}prompts/`;
export const BOT_PROMPT_DETAIL_PATH = `${BOT_PROMPT_PATH}{prompt_uuid}/`;

export const BOT_AI_CONVERSATION_PATH = `${BOT_DETAIL_PATH}ai-conversations/`;
export const BOT_AI_CONVERSATION_INVALIDATE_ALL_PATH = `${BOT_DETAIL_PATH}ai-conversations/invalidate/`;
export const BOT_AI_CONVERSATION_INVALIDATE_PATH = `${BOT_AI_CONVERSATION_PATH}{conversation_id}/invalidate/`;

export const BOT_SESSIONS_PATH = `${BOT_DETAIL_PATH}sessions/`;
export const BOT_SESSION_DETAIL_PATH = `${BOT_SESSIONS_PATH}{session_uuid}/`;
export const BOT_SESSION_BLOCK_PATH = `${BOT_SESSION_DETAIL_PATH}block/`;
export const BOT_SESSION_MARK_READ_ALL_PATH = `${BOT_SESSION_DETAIL_PATH}read-all/`;
export const BOT_SESSION_CONVERSATION_PATH = `${BOT_SESSION_DETAIL_PATH}messages/`;
export const BOT_SESSION_CONVERSATION_READ_PATH = `${BOT_SESSION_CONVERSATION_PATH}read/`;
