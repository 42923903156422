import React from 'react';
import propTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

function ProtectedRoute({ isLoggedIn, Component, path }) {
  return isLoggedIn ? (
    <Routes>
      {' '}
      <Route element={<Component />} path={path} />{' '}
    </Routes>
  ) : (
    <Routes>
      {' '}
      <Route path="/admin" element={<Navigate to="/" />} />
    </Routes>
  );
}

ProtectedRoute.propTypes = {
  path: propTypes.string.isRequired,
  isLoggedIn: propTypes.bool.isRequired,
  Component: propTypes.object.isRequired,
};

export default ProtectedRoute;
