import React from 'react';

import { BotConsumer } from '../context/bot';
import { setBotData as botSetter } from '../reducers/botReducer';

const BotHook = (BaseComponent) =>
  function (props) {
    const [consumer, dispatch] = BotConsumer();

    function setBotData(data) {
      dispatch(botSetter(data));
      localStorage.setItem('lastBot', data.selectedBot.uuid || {});
    }

    return (
      <BaseComponent
        {...props}
        bots={consumer.bots || []}
        selectedBot={consumer.selectedBot || {}}
        setBotData={(data) => setBotData(data)}
      />
    );
  };

export default BotHook;
