/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Layout, Row, Select } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo } from './Style';

import MenueItems from './MenueItems';
import BotHook from '../hooks/bot';
import AccountHook from '../hooks/account';
import Alert from '../components/alerts/alerts';
import AuthInfo from '../components/utilities/auth-info/info';

import { ReactComponent as MySVG } from '../static/img/icon/left-bar.svg';

const { theme } = require('../config/theme/themeVariables');

const { Option } = Select;
const { Header, Content, Sider } = Layout;

const layoutMode = `lightMode`;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        hide: true,
        collapsed: false,
        bots: props.bots,
        setBotData: props.setBotData,
        selectedBot: props.selectedBot,
        accountData: props.accountData,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentDidUpdate(prevProps, prevState) {
      const { bots, selectedBot } = this.props;
      if (bots.length !== prevState.bots.length || selectedBot.uuid !== prevProps.selectedBot.uuid) {
        this.setState({
          ...prevState,
          bots,
          selectedBot,
        });
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, accountData, bots, selectedBot = {}, setBotData } = this.state;
      const isOnboarded = accountData.subscription && accountData.has_bots;
      const isFreePlan = accountData.subscription?.plan?.is_free_plan;

      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
        });
      };

      const SideBarStyle = {
        margin: '63px 0 0 0',
        padding: '20px 20px 55px 0',
        overflowY: 'auto',
        height: isFreePlan ? 'calc(100vh - 62px)' : '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 988,
      };

      function renderThumb({ style }) {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      }
      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          right: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div className="[&>div]:bg-regular dark:[&>div]:bg-[#32333f]" style={thumbStyle} />;
      };
      function renderView({ style }) {
        const customStyle = {
          marginRight: '-17px',
        };
        return <div style={{ ...style, ...customStyle }} />;
      }

      return (
        <>
          {isFreePlan ? (
            <div className="fixed top-0 left-0 right-0 z-99998 bg-white">
              <Alert
                banner
                type="warning"
                message={
                  <span>
                    Enjoying the product on our free plan?{' '}
                    <Link to="/admin/billing/" className="text-primary">
                      Click here
                    </Link>{' '}
                    to upgrade and unlock all limits!
                  </span>
                }
              />
            </div>
          ) : (
            <></>
          )}
          <LayoutContainer>
            <Layout className={`layout ${isFreePlan ? 'pt-[62px]' : ''}`}>
              <Header
                style={{
                  left: 0,
                  width: '100%',
                  position: 'fixed',
                }}
                className="p-0 flex items-center justify-between bg-white border-b-1 border-regular h-[72px] z-998"
              >
                <div className="flex flex-row items-center flex-1 h-full">
                  <div className="pr-5 ssm:pl-[15px] ssm:pr-[15px] pl-[30px] xs:pl-[20px] min-w-[240px] ssm:min-w-[220px] xs:min-w-[170px] h-full grid align-middle dark:bg-[#323541]">
                    <div className="flex items-center justify-between">
                      <Link to="/admin">
                        <img
                          alt=""
                          className="w-full max-w-[100px] xs:max-w-[100px]"
                          src={require(`../static/img/logo.svg`).default}
                        />
                      </Link>
                      {isOnboarded ? (
                        <Button
                          type="link"
                          className="p-0 bg-transparent border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary text-[#525768] hover:text-primary"
                          onClick={toggleCollapsed}
                        >
                          <MySVG />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between flex-auto mr-[10px] [&>div:first-child]:flex [&>div]:items-center">
                    {isOnboarded ? (
                      <div className="inline-flex gap-3">
                        <Select
                          style={{ width: 200 }}
                          value={selectedBot.uuid}
                          onChange={(botId) =>
                            setBotData({
                              bots,
                              selectedBot: bots.find((bot) => bot.uuid === botId),
                            })
                          }
                          className="[&>div]:border-normal dark:[&>div]:border-white10 [&>div]:rounded-6 [&>.ant-select-arrow]:text-theme-gray dark:[&>.ant-select-arrow]:text-white60 [&>div>div>div>span]:bg-transparent [&>div]:h-[38px] [&>div>div>div>span]:items-center [&>div>.ant-select-selection-item]:flex [&>div>.ant-select-selection-item]:items-center dark:[&>div>.ant-select-selection-item]:text-white60"
                        >
                          {bots.map((bot) => {
                            return (
                              <Option key={bot.uuid} value={bot.uuid}>
                                {bot.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <Link to={accountData.can_create_more_bots ? '/admin/create-bot' : '/admin/billing'}>
                          <Button className="bg-primary-transparent hover:bg-hbr-primary border-none text-primary hover:text-white text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-[4px] px-[20px] h-[38px]">
                            Create New Bot
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                    <div className="flex flex-row items-center md:hidden">
                      <AuthInfo isOnboarded={isOnboarded} accountData={accountData} />
                    </div>
                  </div>
                  <div className="hidden md:flex items-center pr-[25px] ssm:pr-[10px]">
                    <Link className="inline-flex text-light dark:text-white60" onClick={onShowHide} to="#">
                      <UilEllipsisV className="w-[18px] h-[18px]" />
                    </Link>
                  </div>
                </div>
              </Header>
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <SmallScreenAuthInfo hide={hide}>
                    <AuthInfo isOnboarded={isOnboarded} accountData={accountData} />
                  </SmallScreenAuthInfo>
                </Col>
              </Row>
              <Layout>
                {isOnboarded ? (
                  <ThemeProvider theme={theme}>
                    <Sider
                      width={240}
                      style={SideBarStyle}
                      collapsed={collapsed}
                      className="border-r-1 border-regular"
                      theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                    >
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderView={renderView}
                        renderThumbVertical={renderThumb}
                        renderTrackVertical={renderTrackVertical}
                      >
                        <MenueItems />
                      </Scrollbars>
                    </Sider>
                  </ThemeProvider>
                ) : (
                  <></>
                )}
                <Layout
                  className="atbd-main-layout"
                  style={{
                    ...(isOnboarded
                      ? {}
                      : {
                          marginLeft: 0,
                        }),
                  }}
                >
                  <Content>
                    <WrappedComponent {...this.props} />
                    <FooterStyle className="bg-white dark:bg-[#1B1E2B]">
                      <Row>
                        <Col md={12} xs={24}>
                          <span className="inline-block w-full font-medium admin-footer__copyright md:text-center text-theme-gray dark:text-white60 md:mb-[10px]">
                            © 2023
                            <Link className="mx-[4px] text-primary" to="#">
                              Web2Chat
                            </Link>
                          </span>
                        </Col>
                      </Row>
                    </FooterStyle>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
            {window.innerWidth <= 991 ? (
              <span className={collapsed ? 'web2chat-shade' : 'web2chat-shade show'} onClick={toggleCollapsed} />
            ) : (
              ''
            )}
          </LayoutContainer>
        </>
      );
    }
  }

  return AccountHook(BotHook(LayoutComponent));
};
export default ThemeLayout;
