import { Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UilTag,
  UilPalette,
  UilWindow,
  UilEllipsisV,
  UilRobot,
  UilSetting,
  UilEnvelopeCheck,
  UilCreateDashboard,
} from '@iconscout/react-unicons';

import { NavTitle } from './Style';

function MenuItems() {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = (mainPath && mainPath.split('/')) || [];

  const items = [
    getItem(<NavLink to={`${path}`}>{t('dashboard')}</NavLink>, 'dashboard', <UilCreateDashboard />),
    getItem(<NavLink to={`${path}/inbox/`}>{t('inbox')}</NavLink>, 'inbox', <UilEnvelopeCheck />),
    getItem(
      <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
        {t('train')}
      </NavTitle>,
      'train-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink to={`${path}/sources/`}>{t('sources')}</NavLink>,
      'sources',
      <NavLink className="menuItem-iocn" to={`${path}/sources/`}>
        <UilWindow />
      </NavLink>,
    ),
    getItem(
      <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
        {t('customizations')}
      </NavTitle>,
      'customisation-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink to={`${path}/appearance/`}>{t('appearance')}</NavLink>,
      'appearance',
      <NavLink className="menuItem-iocn" to={`${path}/appearance/`}>
        <UilPalette />
      </NavLink>,
    ),
    getItem(
      <NavLink to={`${path}/prompts/`}>{t('prompts')}</NavLink>,
      'prompts',
      <NavLink className="menuItem-iocn" to={`${path}/prompts/`}>
        <UilTag />
      </NavLink>,
    ),
    getItem(
      <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
        {t('advanced')}
      </NavTitle>,
      'advanced-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink to={`${path}/ai-replies/`}>{t('ai-replies')}</NavLink>,
      'ai-replies',
      <NavLink className="menuItem-iocn" to={`${path}/ai-replies/`}>
        <UilRobot />
      </NavLink>,
    ),
    getItem(
      <NavLink to={`${path}/settings/`}>{t('settings')}</NavLink>,
      'settings',
      <NavLink className="menuItem-iocn" to={`${path}/settings/`}>
        <UilSetting />
      </NavLink>,
    ),
  ];

  return (
    <Menu
      items={items}
      overflowedIndicator={<UilEllipsisV />}
      defaultSelectedKeys={[`${mainPathSplit.length >= 2 ? mainPathSplit[1] : 'dashboard'}`]}
    />
  );
}

export default MenuItems;
