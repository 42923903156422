import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { UilEnvelopeCheck, UilBell, UilSignout, UilAngleDown, UilCreditCard } from '@iconscout/react-unicons';

import { AUTHORIZATION_KEY } from '../../../global_constants';

import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';

const AuthInfo = React.memo(({ isOnboarded, accountData }) => {
  const navigate = useNavigate();

  const signOut = (e) => {
    e.preventDefault();
    localStorage.removeItem(AUTHORIZATION_KEY);
    navigate(0);
  };

  const path = '/admin';

  const userContent = (
    <div>
      <div className="min-w-[280px] sm:min-w-full pt-4">
        <figure className="flex items-center text-sm rounded-[8px] bg-section dark:bg-white10 py-[20px] px-[25px] mb-[12px]">
          <img
            alt=""
            width={40}
            height={40}
            className="mr-4"
            src={require(`../../../static/img/lego/${accountData.picture_id}.jpg`)}
          />
          <figcaption>
            <Heading className="text-dark dark:text-white87 mb-0.5 text-sm" as="h5">
              {accountData.email.split('@')[0]}
            </Heading>
            <p className="mb-0 text-xs text-body dark:text-white60">{accountData.email}</p>
          </figcaption>
        </figure>
        <ul className="mb-0">
          <li>
            <Link
              to="/admin/billing/"
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilCreditCard className="w-4 h-4 mr-3" /> Billing
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              to="https://docs.web2chat.ai/"
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilBell className="w-4 h-4 mr-3" /> Help
            </Link>
          </li>
        </ul>
        <Link
          to="#"
          onClick={signOut}
          className="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white60 dark:text-white87 mx-[-15px] mb-[-15px] rounded-b-6"
        >
          <UilSignout className="w-4 h-4 mr-3" /> Sign Out
        </Link>
      </div>
    </div>
  );

  return (
    <div className="flex items-center justify-end flex-auto">
      {isOnboarded ? (
        <div className="flex gap-6 mr-3">
          <NavLink to={`${path}/inbox/`}>
            <UilEnvelopeCheck color="#a0a0a0" />
          </NavLink>
        </div>
      ) : (
        <></>
      )}
      <div className="flex ml-3 mr-4 ssm:mr-0 ">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="flex items-center text-light whitespace-nowrap">
            <Avatar src={require(`../../../static/img/lego/${accountData.picture_id}.jpg`)} />
            <span className="mr-1.5  ml-2.5  text-body dark:text-white60 text-sm font-medium md:hidden">
              {accountData.email.split('@')[0]}
            </span>
            <UilAngleDown className="w-4 h-4 md:ml-[5px]" />
          </Link>
        </Popover>
      </div>
    </div>
  );
});

AuthInfo.propTypes = {
  accountData: PropTypes.object,
  isOnboarded: PropTypes.bool.isRequired,
};

export default AuthInfo;
