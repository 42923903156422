import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import './i18n/config';
import reportWebVitals from './reportWebVitals';

import { accountDataReducer, initialState as accountData } from './reducers/accountDataReducer';
import { AccountDataProvider } from './context/accountData';

import { BotProvider } from './context/bot';
import { botReducer, initialState as botData } from './reducers/botReducer';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <AccountDataProvider initialState={accountData} reducer={accountDataReducer}>
    <BotProvider initialState={botData} reducer={botReducer}>
      <App />
    </BotProvider>
  </AccountDataProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
