import linkifyIt from 'linkify-it';

const linkify = linkifyIt();

export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const sum = (data, key) =>
  data.reduce(function (prev, cur) {
    return prev + cur[key];
  }, 0);

export const extractTextFromPDF = (pdfUrl) => {
  const pdfTask = window.pdfjsLib.getDocument(pdfUrl);
  return pdfTask.promise.then(function (pdf) {
    const totalPageCount = pdf.numPages;
    const countPromises = [];
    // eslint-disable-next-line no-plusplus
    for (let currentPage = 1; currentPage <= totalPageCount; currentPage++) {
      const pageTask = pdf.getPage(currentPage);
      countPromises.push(
        pageTask.then(function (page) {
          const textContent = page.getTextContent();
          return textContent.then(function (text) {
            return text.items
              .map(function (s) {
                return s.str;
              })
              .join('');
          });
        }),
      );
    }

    return Promise.all(countPromises).then(function (texts) {
      return texts.join('');
    });
  });
};

export const getTextLength = (text) => {
  return text.replace(/(\r\n|\n|\r)/gm, '').length;
};

export const isFileSizeValid = (file, sizeInKB = 100) => {
  const isLt200KB = file.size / 1024 < sizeInKB;
  return isLt200KB;
};

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getInitials = (str) => {
  return str
    .split(' ')
    .map(function (word) {
      return word.length ? word[0].toUpperCase() : '';
    })
    .join('');
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}....`;
  }
  return text;
};

export const getBrowserName = (userAgent) => {
  let browserName;

  if (!userAgent) {
    return '-';
  }

  if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
    browserName = 'Opera';
  } else if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Chrome';
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'Safari';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Firefox';
  } else if (userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    browserName = 'IE';
  } else if (userAgent.indexOf('Edge') !== -1) {
    browserName = 'Edge';
  } else {
    browserName = 'Other';
  }

  return browserName;
};

export const formatNumber = (num) => {
  let divisor = 0;
  let symbol = '';

  if (num >= 1000000000) {
    divisor = 1000000000;
    symbol = 'B';
  } else if (num >= 1000000) {
    divisor = 1000000;
    symbol = 'M';
  } else if (num >= 1000) {
    divisor = 1000;
    symbol = 'K';
  }

  if (divisor) {
    let formattedNum = (num / divisor).toFixed(2);
    // Remove trailing 0s
    formattedNum = parseFloat(formattedNum).toString();
    return `${formattedNum}${symbol}`;
  }

  return num.toString();
};

export const parseAndLinkifyMessage = (msg, escape = true) => {
  if (escape) {
    msg = JSON.parse(`"${msg}"`);
  }
  return linkify.match(msg)
    ? msg.replace(linkify.match(msg)[0].raw, `[${linkify.match(msg)[0].raw}](${linkify.match(msg)[0].url})`)
    : msg;
};

export const getWidgetUrl = (botUUid) => {
  return `https://widget.static.web2chat.ai/s/${botUUid}.js`;
};
