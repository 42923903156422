import PropTypes from 'prop-types';
import { ConfigProvider, Spin } from 'antd';
import React, { lazy, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import './static/css/style.css';
import 'antd/dist/antd.less';

import config from './config/config';
import { getUserDetail, listBot } from './actions';

import Auth from './routes/auth';
import Admin from './routes/admin';
import BotHook from './hooks/bot';
import AccountHook from './hooks/account';
import ProtectedRoute from './components/utilities/protectedRoute';

const NotFound = lazy(() => import('./container/pages/404'));

const { theme } = config;
const lastBotId = localStorage.getItem('lastBot');

function ProviderConfig({ isLoggedIn }) {
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction="ltr">
      <ThemeProvider theme={{ ...theme, mainContent: 'lightMode' }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Routes>
              <Route path="/*" element={<Auth />} />{' '}
            </Routes>
          ) : (
            <Routes>
              <Route path="/admin/*" element={<ProtectedRoute isLoggedIn={isLoggedIn} path="/*" Component={Admin} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Routes>
              <Route path="/" element={<Navigate to="/admin" />} />
            </Routes>
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

ProviderConfig.propTypes = {
  isLoggedIn: PropTypes.bool,
};

function App({ isLoggedIn, accountData, setAccountData, setBotData }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserDetail()
      .then((response) => {
        setAccountData(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (accountData.has_bots && accountData.subscription) {
        listBot().then((response) => {
          const { data } = response;
          setBotData({
            bots: data,
            selectedBot: data.length ? data.find((item) => item.uuid === lastBotId) || data[0] : {},
          });
        });
      }
    }
  }, [isLoggedIn]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spin />
      </div>
    );
  }

  return <ProviderConfig isLoggedIn={isLoggedIn} />;
}

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  accountData: PropTypes.object,
  setBotData: PropTypes.func,
  setAccountData: PropTypes.func,
};

export default AccountHook(BotHook(App));
